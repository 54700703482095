<template>
  <div class="new-transaction">
    <h2 class="title">{{ $t('categories.newTitle') }}</h2>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>

    <form @submit.prevent="create">
      <input-group
        :label="$t('categories.name')"
        name="name"
        v-model="category.name"
        icon="fas fa-pen"
        required
      />

      <button type="submit" class="button is-primary">{{ $t('common.submit') }}</button>
    </form>
  </div>
</template>

<script>
import InputGroup from '@/components/basic/InputGroup'
import { mapState } from 'vuex'

export default {
  name: 'new-category',
  components: {
    InputGroup
  },
  data () {
    return {
      category: null,
      error: null
    }
  },
  created () {
    this.clearCategory()
  },
  computed: {
    ...mapState({
      accountBook: state => state.accountBooks.current
    })
  },
  methods: {
    async create () {
      try {
        await this.$store.dispatch('categories/create', this.category)
      } catch (error) {
        this.error = error.response.data.message
      }

      this.$router.push(`/${this.accountBook.slug}/categories`)
    },
    clearCategory () {
      this.category = {
        name: null,
        accountBook: this.accountBook._id
      }
    }
  }
}
</script>

<style scoped>

</style>
